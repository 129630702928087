var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.ramos, function(regra, index) {
      return _c(
        "div",
        { key: index, staticClass: "branch-container parametrizacao" },
        [
          _c(
            "vs-row",
            [
              _c("vs-col", { staticClass: "title" }, [
                _vm._v("\n        " + _vm._s(regra.Name) + "\n        "),
                _c("span", { staticClass: "pl-3" }, [
                  _vm._v(
                    "\n          Nroº Processo SUSEP: " +
                      _vm._s(regra.SUSEPProcessCode) +
                      "\n        "
                  )
                ])
              ])
            ],
            1
          ),
          _vm._l(_vm.modalityGroupList[regra.Id], function(group, index) {
            return _c(
              "div",
              { key: index, staticClass: "modality-group-content" },
              [
                _c(
                  "vs-row",
                  {
                    staticClass: "modality-group-title",
                    attrs: { "vs-type": "flex" }
                  },
                  [
                    _c("h4", { staticClass: "font-bold" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(group.ModalityGroup.Name) +
                          "\n          "
                      ),
                      _c("strong", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.groupList[group.ModalityGroup.Id].length)
                        )
                      ])
                    ]),
                    _c(
                      "vs-button",
                      {
                        attrs: { type: "border" },
                        nativeOn: {
                          click: function($event) {
                            return _vm.addModality(
                              regra.Id,
                              group.ModalityGroup.Id
                            )
                          }
                        }
                      },
                      [_vm._v("Adicionar Modalidade")]
                    )
                  ],
                  1
                ),
                _c(
                  "vs-row",
                  { staticClass: "data-title" },
                  [
                    _c("vs-col", { attrs: { "vs-lg": "6" } }, [
                      _vm._v("Modalidade")
                    ]),
                    _c("vs-col", { attrs: { "vs-lg": "4" } }, [
                      _vm._v("Status")
                    ]),
                    _c(
                      "vs-col",
                      {
                        attrs: {
                          "vs-lg": "2",
                          "vs-type": "flex",
                          "vs-justify": "end"
                        }
                      },
                      [_vm._v("Ações ")]
                    )
                  ],
                  1
                ),
                _vm._l(_vm.groupList[group.ModalityGroup.Id], function(
                  modality,
                  indexGroup
                ) {
                  return _c(
                    "vs-row",
                    { key: indexGroup, staticClass: "data-content item" },
                    [
                      _c("vs-col", { attrs: { "vs-lg": "6" } }, [
                        _vm._v(_vm._s(modality.Name))
                      ]),
                      _c("vs-col", { attrs: { "vs-lg": "4" } }, [
                        _vm._v(_vm._s(modality.IsDeleted ? "Inativa" : "Ativa"))
                      ]),
                      _c(
                        "vs-col",
                        {
                          attrs: {
                            "vs-lg": "2",
                            "vs-type": "flex",
                            "vs-justify": "end"
                          }
                        },
                        [
                          _c(
                            "vs-dropdown",
                            {
                              staticClass: "cursor-pointer",
                              attrs: {
                                "vs-custom-content": "",
                                "vs-trigger-click": ""
                              }
                            },
                            [
                              _c(
                                "vs-button",
                                {
                                  attrs: {
                                    color: "dark",
                                    type: "flat",
                                    title: "Ações"
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "onpoint-dots-three-vertical icon-dots-three-vertical",
                                    attrs: { role: "button" }
                                  })
                                ]
                              ),
                              _c(
                                "vs-dropdown-menu",
                                {
                                  staticStyle: {
                                    width: "200px",
                                    "z-index": "53001 !important"
                                  }
                                },
                                [
                                  _c(
                                    "vs-dropdown-item",
                                    {
                                      nativeOn: {
                                        click: function($event) {
                                          return _vm.goTo(modality)
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "p",
                                        { staticClass: "text-base px-6 py-2" },
                                        [_c("span", [_vm._v("Editar")])]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                })
              ],
              2
            )
          })
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }