<template>
  <div>
    <div
      class="branch-container parametrizacao"
      v-for="(regra, index) in ramos"
      :key="index"
    >
      <vs-row>
        <vs-col class="title">
          {{ regra.Name }}
          <span class="pl-3">
            Nroº Processo SUSEP: {{ regra.SUSEPProcessCode }}
          </span>
        </vs-col>
      </vs-row>

      <div
        v-for="(group, index) in modalityGroupList[regra.Id]"
        :key="index"
        class="modality-group-content"
      >
        <vs-row vs-type="flex" class="modality-group-title">
          <h4 class="font-bold">
            {{ group.ModalityGroup.Name }}
            <strong> {{ groupList[group.ModalityGroup.Id].length }}</strong>
          </h4>

          <vs-button
            type="border"
            @click.native="addModality(regra.Id, group.ModalityGroup.Id)"
            >Adicionar Modalidade</vs-button
          >
        </vs-row>

        <vs-row class="data-title">
          <vs-col vs-lg="6">Modalidade</vs-col>
          <vs-col vs-lg="4">Status</vs-col>
          <vs-col vs-lg="2" vs-type="flex" vs-justify="end">Ações </vs-col>
        </vs-row>

        <vs-row
          v-for="(modality, indexGroup) in groupList[group.ModalityGroup.Id]"
          :key="indexGroup"
          class="data-content item"
        >
          <vs-col vs-lg="6">{{ modality.Name }}</vs-col>
          <vs-col vs-lg="4">{{
            modality.IsDeleted ? "Inativa" : "Ativa"
          }}</vs-col>
          <vs-col vs-lg="2" vs-type="flex" vs-justify="end">
            <vs-dropdown
              vs-custom-content
              vs-trigger-click
              class="cursor-pointer"
            >
              <vs-button color="dark" type="flat" title="Ações">
                <i class="onpoint-dots-three-vertical icon-dots-three-vertical" role="button"></i>
              </vs-button>
              <vs-dropdown-menu
                style="width: 200px; z-index: 53001 !important;"
              >
                <!--ABRIR MODAL PARA EDITAR OS DADOS DO RAMO-->
                <vs-dropdown-item @click.native="goTo(modality)">
                  <p class="text-base px-6 py-2">
                    <span>Editar</span>
                  </p>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </vs-col>
        </vs-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  components: {},
  data() {
    return {
      ramos: [],
      modalidadeList: [],
      groupList: [],
      modalityGroupList: []
    };
  },
  async mounted() {
    await this.getRamosModality();
  },
  methods: {
    ...mapActions("seguradora-module", [
      "getAllBranch",
      "getModalitiesByBranch"
    ]),

    async getRamosModality() {
      await this.$onpoint.loading(async () => {
        await this.getAllBranch().then(response => {
          this.ramos = response;
          response.map(async branch => {
           await this.$onpoint.loading(async () => {
              return await this.getModalitiesByBranch(branch.Id).then(
                async modalitys => {
                  this.modalityGroupList[branch.Id] = modalitys.filter(
                    (v, i, a) =>
                      a.findIndex(
                        t => t.ModalityGroup.Name === v.ModalityGroup.Name
                      ) === i
                  );
                  await modalitys.map(async grupo => {
                    if (!this.groupList[grupo.ModalityGroup.Id]) {
                      this.groupList[grupo.ModalityGroup.Id] = [];
                    }
                    this.groupList[grupo.ModalityGroup.Id].push(grupo);
                  });
                  this.$forceUpdate();
                }
              );
            });
          });
        });
      });
    },

    addModality(regraId, modalityId) {
      this.$router.push({
        name: "param-detalhar-modalidade",
        params: {
          branchId: regraId,
          modalityGroupId: modalityId
        }
      });
    },

    goTo(item) {
      this.$router.push({
        name: "param-configurar-modalidade",
        params: {
          modalidadeId: item.Id,
          modalidadeUniqueId: item.UniqueId,
          branchId: item.ModalityGroup.BranchId,
          modalityGroupId: item.ModalityGroup.Id
        }
      });
    }
  },
  created() {
    this.$appInsights.trackPageView({
      name: "param-modalidade",
      url: window.location.href
    });

  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/vuexy/components/vxtable.scss";

.branch-container {
  background: #fff;
  box-shadow: 0px 3px 10px 8px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  margin-bottom: 24px;

  .title {
    padding: 32px 16px;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height, or 138% */

    /* grayscale/gray-4 */

    color: #8e8e8e;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .modality-group-content:not(:last-of-type) {
    border-bottom: 1px solid #e4e1e1;
  }

  .modality-group-content:not(:first-of-type) {
    padding-top: 16px;
  }

  .modality-group-content {
    margin: 0 16px 16px;
    padding-bottom: 16px;

    .modality-group-title {
      justify-content: space-between;
      padding-bottom: 28px;
      padding-top: 0px;
    }

    .data-content {
      color: #50555a;
      font-size: 14px;
      font-weight: 700;
    }

    .data-title {
      color: #8e8e8e;
      font-size: 12px;
      font-weight: 700;
    }
  }
}

.icon-dots-three-vertical::before{
  position: relative;
  top: 2px;
  font-size: 24px;
  color: #afafaf;
}
</style>

<style lang="scss">
.vs-popup {
  width: auto !important;
}
</style>
